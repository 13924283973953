body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.rate-logo {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.rate-logo span {
	font-size: 11px;
	font-weight: normal;
	text-transform: uppercase;
}

.rate-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	text-align: center;
}

.rate-content span {
	font-size: 12.5px;
}

.rate-content span.rate-title {
	font-size: 13.5px;
	font-weight: bold;
}

.rate-content .rating {
	margin-top: 10px;
	padding: 20px 10px;
	display: flex;
	width: 100%;
}

.rate-content .rating-feedback {
	margin-top: 10px;
	padding: 10px 20px;
	width: 100%;
}

.event-confirm-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px 0;
	text-align: center;
}

.event-confirm-content span {
	font-size: 19px;
	font-weight: bold;
	text-transform: uppercase;
}

.rate-action {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
}

@media (min-width: 992px) {
	.navbar-nav .nav-item {
		margin-right: 0 !important;
	}

	.navbar-nav .nav-item svg {
		fill: rgba(255, 255, 255, 0.95);
	}
}

@media (max-width: 991px) {
	.navbar-nav .nav-item svg {
		fill: #172b4d;
	}
}

.favorite {
	color: red;
	font-size: 20px;
}

.btn-favorite {
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: transparent;
}

.btn-favorite:hover {
	background-color: #dedede;
}

.btn-favorite:active {
	background-color: lightgray;
}

.btn-favorite i {
	font-size: 20px;
	color: red;
}

.notify {
	padding: 20px;
	border-radius: 10px;
	background-color: gray;
	margin: 10px 0;
	color: black;
}

.notify-info {
	background-color: #abd4ab;
	color: black;
}

.notify-warn {
	background-color: #fec070;
	color: black;
}

.confirm-alert-actions {
	display: flex;
	flex-direction: row;
	row-gap: 3rem;
}

#react-confirm-alert {
	position: relative;
	z-index: 1111;
}