.wrapper {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.logo {
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 48px;
  }
}

.content {
  flex: 1;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    color: black;
    font-size: 1rem;
    font-weight: bold;
  }

  p {
    color: black;
    font-size: 14px;
    margin-bottom: 4px;
  }
}