/* Make the effect more visible */
.host-partial-single-hour {
  border-left: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
}

// Half-hour availability styles for weekly scheduler
.sh-availability {
  .time-block {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: visible;
    background-color: transparent !important; /* Override any existing background */
    
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      border-top: 1px dotted #aaa;
      z-index: 1;
      pointer-events: none;
    }
    
    .half-block {
      flex: 1;
      width: 100%;
      height: 50%;
      transition: background-color 0.1s ease;
      background-color: transparent;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        background-color: rgba(79, 129, 230, 0.3);
      }
      
      .time-label-display {
        color: white;
        font-size: 10px;
        font-weight: bold;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.9);
        pointer-events: none;
        user-select: none;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.25);
        padding: 1px 3px;
        border-radius: 2px;
        white-space: nowrap;
        max-width: 95%;
        overflow: hidden;
        
        @media (max-width: 767px) {
          font-size: 7px;
          padding: 1px 2px;
        }
      }
    }
    
    // All selected half-blocks use the same color
    &.first-half .top-half,
    &.second-half .bottom-half,
    &.full-hour .top-half,
    &.full-hour .bottom-half {
      background-color: #4f81e6;
    }
    
    &.full-hour::after {
      border-top-color: rgba(255, 255, 255, 0.7);
    }
  }
}

/* Team Viewer Scheduler Styles */
.team-viewer-scheduler {
  .week-display {
    font-size: 18px;
    font-weight: 600;
    
    .current-week-badge {
      background-color: #28a745;
      color: white;
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 3px;
    }
  }
  
  /* Common grid styles */
  .availability-grid {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    
    .days-header {
      display: flex;
      background-color: #f5f5f5;
      border-bottom: 1px solid #ddd;
      
      .time-label {
        width: 80px;
        border-right: 1px solid #ddd;
        padding: 10px;
        
        @media (max-width: 767px) {
          width: 40px;
          padding: 5px;
        }
      }
      
      .day-label {
        flex: 1;
        padding: 10px;
        text-align: center;
        border-right: 1px solid #ddd;
        min-width: 120px;
        
        @media (max-width: 767px) {
          min-width: 40px;
          padding: 5px;
        }
        
        &:last-child {
          border-right: none;
        }
        
        .day-name {
          font-weight: bold;
          margin-bottom: 5px;
          font-size: 14px;
          white-space: nowrap;
          
          .mobile-day {
            display: none;
          }
          
          @media (max-width: 767px) {
            .desktop-day {
              display: none;
            }
            
            .mobile-day {
              display: inline;
              font-size: 16px;
            }
          }
        }
        
        .date {
          font-size: 12px;
          color: #666;
          white-space: nowrap;
          
          .mobile-date {
            display: none;
          }
          
          @media (max-width: 767px) {
            .desktop-date {
              display: none;
            }
            
            .mobile-date {
              display: inline;
            }
          }
        }
      }
    }
    
    .time-slots {
      .time-row {
        display: flex;
        border-bottom: 1px solid #ddd;
        
        &:last-child {
          border-bottom: none;
        }
        
        .time-label {
          width: 80px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f9f9f9;
          border-right: 1px solid #ddd;
          
          @media (max-width: 767px) {
            width: 40px;
            padding: 5px;
            font-size: 12px;
          }
          
          .am-pm {
            font-size: 12px;
            margin-left: 2px;
            color: #666;
          }
        }
      }
    }
  }
  
  /* Heatmap specific styles */
  .heatmap-slots {
    .time-row {
      display: flex;
      border-bottom: 1px solid #ddd;
      height: 60px; /* Fixed height for consistency */
        
      @media (max-width: 767px) {
        height: 45px;
      }
        
      &:last-child {
        border-bottom: none;
      }
    }
  }
  
  .heatmap-cell-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    flex: 1;
    border-right: 1px solid #ddd;
    
    &:last-child {
      border-right: none;
    }
    
    .heatmap-cell {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: relative;
      transition: transform 0.1s ease;
      
      &:hover {
        transform: scale(1.05);
        z-index: 10;
      }
      
      .host-count {
        font-weight: bold;
        color: white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
      }
    }
  }
  
  /* Heat colors for availability count */
  .heat-none {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
  }
  
  .heat-low {
    background-color: #ffc107;
  }
  
  .heat-medium {
    background-color: #fd7e14;
  }
  
  .heat-high {
    background-color: #dc3545;
  }
  
  .heat-very-high {
    background-color: #6610f2;
  }
  
  /* Host table styles */
  .host-table-container {
    margin-top: 30px;
    overflow: hidden;
    
    h3 {
      margin-bottom: 15px;
    }
  }
  
  .host-table-scroll {
    overflow-x: auto;
    margin-bottom: 20px;
    
    /* Special styling for single day view */
    .filtered-day-view {
      width: 100%;
      
      /* Ensure table fills available width */
      table-layout: fixed;
      
      /* Make the time cells take up more width */
      th:first-child {
        width: 150px;
      }
      
      /* Add min-height to cells for better visibility */
      .host-half-hour {
        height: 35px;
      }
    }
  }
  
  .host-table {
    width: max-content;
    min-width: 100%;
    border-collapse: collapse;
    
    &.filtered-day-view {
      /* Maximize hour columns in single day view */
      width: 100%;
      
      /* First column for host names */
      th:first-child, td:first-child {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
      }
      
      th:not(:first-child), td:not(:first-child) {
        width: auto;
      }
    }
    
    th, td {
      border: 1px solid #dee2e6;
      padding: 5px;
      text-align: center;
      vertical-align: middle;
    }
    
    .host-table-date {
      display: flex;
      flex-direction: column;
      
      span:first-child {
        font-weight: bold;
      }
      
      span:last-child {
        font-size: 0.9em;
      }
    }
    
    .hour-header th {
      padding: 2px;
      font-size: 0.8em;
    }
    
    .host-name {
      white-space: nowrap;
      text-align: left;
      padding: 5px 10px;
      font-weight: 600;
      min-width: 150px;
    }
    
    .host-hour-cell {
      padding: 0;
      width: 25px;
      height: 30px;
    }
    
    .host-hour {
      width: 100%;
      height: 100%;
    }
    
    /* Half-hour cells styles */
    .half-hour-cell {
      width: 15px !important;
      max-width: 15px !important;
      padding: 2px !important;
      text-align: center;
      font-size: 12px;
    }
    
    .host-half-hour-cell {
      padding: 0 !important;
      width: 15px !important;
      max-width: 15px !important;
    }
    
    /* Wider cells for single day view */
    .filtered-day-view {
      .half-hour-cell {
        width: 25px !important;
        max-width: 25px !important;
      }
      
      .host-half-hour-cell {
        width: 25px !important;
        max-width: 25px !important;
      }
      
      .host-name {
        width: 150px !important;
        min-width: 150px !important;
        max-width: 150px !important;
      }
    }
    
    .host-half-hour {
      width: 100%;
      height: 30px;
      display: block;
      border: 1px solid #e0e0e0;
      transition: all 0.2s;
      
      &:hover {
        opacity: 0.8;
      }
    }
    
    /* Make hour numbers bolder */
    .hour-header th:nth-child(odd) {
      font-weight: bold;
    }
    
    /* Style for minutes */
    .hour-header th small {
      color: #777;
      font-size: 10px;
    }
  }
  
  /* Host cell status styles */
  .host-cell, .host-hour {
    &.host-available {
      background-color: #28a745;
      
      &.availability-first-half {
        background: linear-gradient(to right, #28a745 50%, transparent 50%);
      }
      
      &.availability-second-half {
        background: linear-gradient(to right, transparent 50%, #28a745 50%);
      }
    }
    
    &.host-booked {
      background-color: #007bff;
    }
    
    &.host-provider-booked {
      background-color: #6f42c1;
    }
    
    &.host-not-available {
      background-color: #f8f9fa;
    }
  }
  
  /* Individual host view */
  .individual-host-grid {
    .time-slots {
      .time-row {
        .host-provider-booked, .host-booked {
          flex: 1;
          border-right: 1px solid #ddd;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          
          @media (max-width: 767px) {
            height: 45px;
          }
          
          &:last-child {
            border-right: none;
          }
          
          .host-cell-content {
            width: 100%;
            height: 100%;
          }
        }
        
        .time-block {
          flex: 1;
          border-right: 1px solid #ddd;
          height: 60px;
          position: relative;
          display: flex;
          flex-direction: column;
          
          @media (max-width: 767px) {
            height: 45px;
          }
          
          &:last-child {
            border-right: none;
          }
          
          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            border-top: 1px dotted #aaa;
            z-index: 1;
            pointer-events: none;
          }
          
          .half-block {
            flex: 1;
            width: 100%;
            height: 50%;
            transition: background-color 0.1s ease;
            background-color: transparent;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          // All selected half-blocks use the same color
          &.first-half .top-half,
          &.second-half .bottom-half,
          &.full-hour .top-half,
          &.full-hour .bottom-half {
            background-color: #4f81e6;
          }
          
          &.full-hour::after {
            border-top-color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }
}